import React from 'react';
import { StickyProvider } from 'contexts/app/app.provider';
import Seo from 'components/seo';
import Subscribe from 'sections/subscribe-form';

import { motion } from 'framer-motion';


import 'rc-drawer/assets/index.css';
import 'react-modal-video/css/modal-video.min.css';

const AnimatedSection = ({ children }) => {
    return (
      <motion.div
        initial={{ opacity: 0, y: 70 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true, amount: 0.3 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    );
  };

export default function IndexPage() {
  return (
    <StickyProvider>
        <Seo
          title="SPLIT"
          description="Get the lastest information on SPLIT"
        />
        <AnimatedSection><Subscribe /></AnimatedSection>
    </StickyProvider>
  );
}
